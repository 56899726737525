import { Dialog, Transition } from "@headlessui/react"
import React, { Fragment, useRef, useEffect, useState, useContext } from "react"
import { EyeIcon } from "@heroicons/react/solid"
// import {ProductDetail} from "./ProductDetail"
import {
  XIcon,
  SearchIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid"
import { NiitlegShinj } from "../graphql/WallpaperPageQuery"
import { VscQuote } from "react-icons/vsc"
import { Link } from "gatsby"
import {
  ProductIdText,
  MoreText,
  ColorText,
  RoomText,
  PatternText,
  OntslogText,
  LuxuryText,
} from "../graphql/QueryById"
import { GatsbyContext } from "../context/context"

export default function QSee({ data, modalClose, modalOpen, setModalOpen }) {
  const { isModalOpen, showModal, hideModal } = useContext(GatsbyContext)
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef()

  // console.log(data)

  // console.log(data.color)

  useEffect(() => {
    modalOpen ? setOpen(true) : setOpen(false)
  }, [modalOpen])

  // console.log(data)
  // console.log(data.urgun)
  // console.log(data.talbai)
  // console.log(data.urt)

  function closeModal() {
    setOpen(false)
    modalClose()
    hideModal()
  }

  // ################# IMPORTED FROM PRODUCTDETAILS #############
  const [value, setValue] = useState(0)

  const colors = (
    <div className="grid grid-cols-4 gap-2 h-14">
      <div className="flex items-center justify-center text-sm leading-tight tracking-tight text-center text-gray-700 bg-gray-100 sm:text-base">
        {ColorText()}
      </div>
      <div className="grid items-center grid-cols-3 col-span-2 gap-2 py-1">
        {/* EDITED ********************* */}
        {data.color
          ? data.color.map((item, index) => {
              const name = item.colorName ?? undefined
              const hex = item.hex
              const baraan = item.baraan
              return hex ? (
                <div key={index} className="relative grid gap-2 group">
                  <div
                    className={`flex items-center w-10 h-10 opacity-90 group-hover:opacity-100 rounded-full border group-hover:border-brand-darker active:z-20 group-focus:z-20 group-hover:z-20 ${
                      name ? "cursor-pointer" : ""
                    }`}
                    style={{ backgroundColor: hex }}
                  ></div>
                  <div
                    className="absolute left-0 z-10 items-center hidden p-20 leading-tight text-gray-800 border rounded-full shadow-lg sm:right-0 group-hover:flex group-focus:flex -top-36 group-hover:z-20 group-focus:z-20 active:z-20"
                    style={{ backgroundColor: hex }}
                  >
                    <div
                      className={`absolute inset-0 z-20 items-center justify-center hidden leading-tight rounded-full group-hover:flex group-focus:flex ${
                        baraan ? "text-white" : "text-gray-800"
                      }`}
                    >
                      {name}
                    </div>
                  </div>
                </div>
              ) : undefined
            })
          : undefined}
      </div>
    </div>
  )

  const country = (
    <div className="grid grid-cols-3 gap-2 h-14">
      <div className="flex items-center justify-center text-sm leading-tight tracking-tight text-center text-gray-700 bg-gray-100 sm:text-base">
        Улс
      </div>
      <div className="grid items-center grid-cols-3 col-span-2 gap-2 py-1">
        {data.country ? (
          <div className="relative grid gap-2 group">
            <div className="grid items-center object-contain w-12 h-full shadow cursor-pointer opacity-90 group-hover:opacity-100">
              {data.flag && data.flag}
            </div>
            <div className="absolute right-0 z-10 items-center hidden p-2 -mt-16 leading-tight border rounded shadow-lg text-brand-darker bg-brand-lighter group-hover:flex group-focus:flex">
              {data.country && data.country}
            </div>
          </div>
        ) : undefined}
      </div>
    </div>
  )

  const brand = (
    <div className="grid grid-cols-3 gap-2 h-14">
      <div className="flex items-center justify-center text-sm leading-tight tracking-tight text-center text-gray-700 bg-gray-100 sm:text-base">
        Брэнд
      </div>
      <div className="grid items-center grid-cols-3 col-span-2 gap-2 py-1">
        {data.brand ? (
          <div className="relative grid gap-2 group">
            <div className="grid items-center object-contain w-24 h-full border cursor-pointer opacity-90 group-hover:opacity-100">
              {data.brandLogo && data.brandLogo}
            </div>
            <div className="absolute right-0 z-10 items-center hidden p-2 -mt-16 leading-tight border rounded shadow-lg text-brand-darker bg-brand-lighter group-hover:flex group-focus:flex">
              {data.brand && data.brand}
            </div>
          </div>
        ) : undefined}
      </div>
    </div>
  )

  // console.log(data.urgun)

  const size = (
    <div className="grid grid-cols-3 gap-2 h-14">
      <div className="flex items-center justify-center text-sm leading-tight tracking-tight text-center text-gray-700 bg-gray-100 sm:text-base">
        Хэмжээ
      </div>
      <div className="grid items-center grid-cols-3 col-span-2 gap-2 py-1">
        <div className="relative grid gap-2 group">
          <div className="flex items-center object-contain space-x-1 opacity-90 group-hover:opacity-100">
            <span>{data.urgun}</span>
            <span>{data.urt}</span>
            <span>
              {data.talbai}
              <sup>2</sup>
            </span>
          </div>
        </div>
      </div>
    </div>
  )

  // ################## Link, Country(Flag), Brand(logo) size(urgun, urt, talbai) ########################
  const busad = [
    {
      name: "Брэнд",
      text: data.brand,
      busadInfoData: data.brandLogo,
    },
    {
      name: "Хэмжээ",
      busadInfoData: [
        {
          text: "Өргөн",
          data: data.urgun,
        },
        {
          text: "Урт",
          data: data.urt,
        },
        {
          text: "Хамрах талбай",
          data: data.talbai,
        },
      ],
    },
  ]

  // ############# GOL SHINJUUD

  const niitleg = NiitlegShinj().map((item, index) => {
    // console.log(item.icon)
    return item.icon ? (
      <div key={index} className="flex group">
        <div className="flex transform cursor-pointer opacity-80 group-hover:opacity-100 group-hover:scale-125">
          {item.icon}
        </div>
        <span className="absolute z-10 items-center justify-center hidden p-2 leading-tight text-center text-white bg-gray-700 rounded shadow-md inset-x-5 group-hover:flex group-focus:flex sm:mt-14 bottom-12 bg-opacity-90">
          {item.name}
        </span>
      </div>
    ) : (
      <span className="text-sm text-gray-700">{item.name}</span>
    )
  })

  // ############### NEMELT SHINJUUD

  // ************** EDITED ***************
  const nemeltuud = [
    { title: OntslogText(), body: data.ontslog },
    { title: LuxuryText(), body: data.luxury },
    { title: PatternText(), body: data.pattern },
    { title: RoomText(), body: data.room },
  ]

  const beforeFilteredNemeltList = nemeltuud.map((item, index) => {
    const nemeltInfo = item?.body ?? undefined
    const title = item?.title ?? undefined
    return (
      nemeltInfo && (
        <div key={index} className="grid grid-cols-4 gap-2">
          <div className="flex items-center justify-center text-sm leading-tight tracking-tight text-center text-gray-700 bg-gray-100 sm:text-base">
            {title}
          </div>
          <div className="relative grid grid-cols-4 col-span-3 gap-4 py-1 pr-2 sm:gap-2">
            {nemeltInfo.map((item, index) => {
              return item.icon ? (
                <div key={index} className="flex gap-2 group">
                  <div className="flex items-center h-full transform cursor-pointer opacity-90 group-hover:opacity-100 group-hover:scale-125">
                    {item.icon}
                  </div>
                  <span className="absolute inset-x-0 z-10 items-center justify-center hidden p-2 leading-tight text-center text-white bg-gray-700 rounded shadow-md group-hover:flex group-focus:flex sm:mt-14 bottom-14 bg-opacity-90">
                    {item.name}
                  </span>
                </div>
              ) : (
                <span className="text-sm text-gray-700">{item.name}</span>
              )
            })}
          </div>
        </div>
      )
    )
  })

  const nemeltList = beforeFilteredNemeltList.filter(Boolean)

  // console.log(nemeltList)

  // ################ BADGE SECTION ####################
  const badges = (
    <div className="absolute flex flex-col items-end space-y-1 top-2 right-2">
      {data.best && (
        <div className="">
          <span className="px-2 py-1 text-xs font-bold text-red-500 uppercase bg-white bg-opacity-50 rounded-full shadow-md">
            Bestseller
          </span>
        </div>
      )}
      {data.newProduct && (
        <div className="flex ">
          <span className="px-2 py-1 text-xs font-bold uppercase bg-white bg-opacity-50 rounded-full shadow-md text-brand">
            New
          </span>
        </div>
      )}
      {data.hot && (
        <div className="">
          <span className="px-2 py-1 text-xs font-bold text-gray-200 uppercase bg-opacity-50 rounded-full shadow-md bg-brand">
            Hot
          </span>
        </div>
      )}
      {data.sale && (
        <div className="">
          <span className="px-2 py-1 text-xs font-bold text-red-700 uppercase bg-red-300 bg-opacity-50 rounded-full shadow-md">
            {data.sale}
          </span>
        </div>
      )}
    </div>
  )

  // ################ IMAGE LOGIC #####################

  const activeStyle = "border-2 border-gray-500 shadow"
  // ************ image - mainImage *************
  const productImages = [data.image]
  // tuslahImages &&
  //   tuslahImages.map(image => {
  //     productImages.push(image)
  //   })

  data.tuslahImages &&
    data.tuslahImages.forEach(image => productImages.push(image))

  const allImages = productImages.map((item, index) => {
    return (
      <div
        key={index}
        className={`flex object-cover h-12 overflow-hidden cursor-pointer ${
          index === value ? activeStyle : ""
        }`}
        onClick={() => setValue(index)}
      >
        {item}
      </div>
    )
  })

  const manyImage = productImages.length > 1 ? true : false

  useEffect(() => {
    const lastImage = allImages.length - 1
    if (value < 0) {
      setValue(lastImage)
    }
    if (value > lastImage) {
      setValue(0)
    }
  }, [value, allImages])

  const [isShowing, setIsShowing] = useState(false)

  // ############# LEFT SIDE ################
  const leftSide = (
    <div className="flex flex-col flex-1 h-full">
      <div className="" onClick={() => setIsShowing(true)}>
        <div className="relative flex object-cover w-full h-56 border border-brand-lighter sm:h-96 group">
          {productImages[value]}

          {/* Mouse hover wallpaper color show */}
          {/* <div className="absolute inset-0 w-full h-full" style={{backgroundColor: wallpaperColor}}>&nbsp;</div> */}

          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 opacity-0 cursor-pointer group-hover:opacity-100">
            <SearchIcon className="w-10 h-10 text-gray-400 transition" />
          </div>

          {/* ###############3 BADGE SECTION ######## */}
          {badges}
        </div>
      </div>

      {/* ############### ALL PRODUCT SMALL LIST */}
      {manyImage && (
        <div className="grid grid-cols-4 gap-1 mt-2">{allImages}</div>
      )}
    </div>
  )

  // ########################################## RIGHT SITE ###########################################
  const rightSide = (
    <>
      <div>
        {/* ############## NIITLEG SHINJUUD */}
        {data.productType === "wallpaper" && (
          <div className="relative grid grid-cols-6 gap-4 my-2 justify-items-center">
            {niitleg}
          </div>
        )}

        {/* ############# NEMELT SHINJUUD */}
        {nemeltList.length !== 0 && (
          <div className="border divide-y rounded divide-brand-lighter divide-opacity-20 border-brand-lighter border-opacity-20">
            {nemeltList}
            {data.color && colors}
            {/* {data.country && country}
            {data.brand && brand}
            {data.urgun && size} */}
          </div>
        )}

        {/* ########### TAILBAR */}
        {data.tailbar && (
          <div className="relative">
            <div className="p-2 pl-4 mt-2 prose-sm">{data.tailbar}</div>
            <div className="absolute inset-y-0 left-0">
              {/* <VscQuote className="text-5xl text-brand-lighter opacity-20" /> */}
            </div>
          </div>
        )}

        {/* {data?.flag && <div className="w-14">{data.flag}</div>} */}
      </div>

      {/* NEXT PREVIOUS BUTTON */}
      <div
        className={`absolute inset-0  border shadow-xl border-brand-light cursor-pointer bg-gray-100 overflow-hidden ${
          isShowing ? "flex " : "hidden"
        }`}
      >
        {/*################ Prev Next Button ###################*/}
        <div className="absolute inset-0 flex items-center justify-between flex-1">
          <button
            onClick={() => setValue(value - 1)}
            className="z-50 py-3 text-3xl focus:outline-none group"
          >
            <ChevronLeftIcon className="w-16 h-16 p-2 text-white bg-black rounded-full outline-none bg-opacity-10 focus:outline-none" />
          </button>
          <button
            onClick={() => setValue(value + 1)}
            className="z-50 py-3 text-3xl focus:outline-none group"
          >
            <ChevronRightIcon className="w-16 h-16 p-2 text-white bg-black rounded-full outline-none bg-opacity-10 focus:outline-none" />
          </button>
        </div>

        <div
          className="absolute inset-0 flex object-cover w-full sm:h-full"
          onClick={() => setIsShowing(isShowing => !isShowing)}
        >
          {productImages[value]}
          {/* <div className="absolute inset-0 h-full w-ful l">&nbsp;</div> */}
        </div>
        {/* <div className="absolute inset-0">&nbsp;</div> */}
        <button
          onClick={() => setIsShowing(isShowing => !isShowing)}
          className="absolute z-20 text-3xl bg-transparent border-transparent top-4 right-4 focus:outline-none"
        >
          <XIcon className="w-10 h-10 p-1 text-gray-600 rounded-full hover:text-white hover:bg-white hover:bg-opacity-40" />
        </button>
      </div>
    </>
  )

  // ############### RETURN START #####################################################
  return (
    <>
      {/* <div
        // type="button"
        onClick={openModal}
        className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium uppercase hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 group"
      >
        <EyeIcon className="w-5 h-5 text-brand group-hover:text-white" />
      </div> */}
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-brand-darkest opacity-60" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-sm align-middle transition-all transform sm:max-w-2xl sm:my-8">
                {/* <div className="relative inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"> */}
                <div className="relative overflow-hidden text-left bg-white shadow-xl rounded-2xl">
                  {/* ########## REFACTOR SECTION START ################### */}
                  <div className="relative grid sm:space-x-1 sm:grid-cols-2 md:space-y-4">
                    {/* ########### LEFT SIDE IMAGE SECTION START ################ */}
                    <div className="p-2">{leftSide}</div>

                    {/* ############# RIGHT SIDE  PRODUCT INFORMATION START ################ */}
                    <div className="static flex flex-col overflow-visible justify-evenly">
                      <div className="p-2 sm:pr-3">
                        <Dialog.Title
                          as="h3"
                          className="absolute flex items-center px-2 text-lg font-medium leading-6 text-gray-900 bg-white rounded sm:px-0 top-4 sm:relative bg-opacity-80 sm:pb-4"
                        >
                          <span className="hidden mr-2 text-sm tracking-tight text-gray-500 sm:flex">
                            {ProductIdText()}:
                          </span>
                          <span className="text-gray-800 sm:text-base">
                            {data.name}
                          </span>
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500"></p>
                        </div>

                        {rightSide}
                      </div>

                      <div className="flex justify-center my-2 sm:my-4">
                        <Link
                          to={`/${data.productType}/${data.slug}`}
                          className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 outline-none hover:bg-gray-100 group active:outline-none"
                          // className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-800 bg-transparent border border-transparent rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 group"
                          // onClick={closeModal}
                          onClick={() => (
                            setOpen(false) 
                            // console.log("Modal closed")
                          )}
                        >
                          {MoreText()}
                          <ArrowNarrowRightIcon className="h-4 pl-1" />
                        </Link>
                      </div>
                    </div>

                    {/* ############# RIGHT SIDE  PRODUCT INFORMATION END ################ */}
                  </div>

                  {/* ########## REFACTOR SECTION END ################### */}
                </div>

                {!isShowing && (
                  <XIcon
                    className="absolute z-40 w-8 h-8 p-1 text-gray-700 transition bg-gray-100 border rounded-full shadow-md -right-2 -top-2 hover:text-white hover:bg-gray-800"
                    // onClick={() => closeModal()}
                    // onClick={setModalOpen}
                    onClick={modalClose}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
