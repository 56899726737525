import React, { Children } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { CurrentLanguage } from "../components/MenuItems"
import Title from "../components/Title"

const TextWelcomeTitleWallpaper = () => {
  const currentLanguage = CurrentLanguage()
  const { TextWelcomeTitleWallpaper } = useStaticQuery(query)

  const nameMn = TextWelcomeTitleWallpaper?.nodes[0]?.data?.nameMn ?? ""
  const nameEn = TextWelcomeTitleWallpaper?.nodes[0]?.data?.nameEn ?? ""
  const name = currentLanguage === "mn" ? nameMn : nameEn

  return name
}

export const NiitlegShinj = () => {
  const currentLanguage = CurrentLanguage()
  const { NiitlegShinj } = useStaticQuery(query)

  const niitlgeList = NiitlegShinj?.nodes.map((item, index) => {
    const nameMn = item?.data?.nameMn?.trim() ?? undefined
    const nameEn = item?.data?.nameEn?.trim() ?? undefined
    const name = currentLanguage === "mn" ? nameMn : nameEn

    const sharp = getImage(item?.data?.Icon?.localFiles[0])

    const icon = sharp ? (
      <GatsbyImage
        image={sharp}
        alt={name ?? "image"}
        className="flex w-10 h-full"
      />
    ) : undefined

    return { name, icon }
  })

  return niitlgeList.filter(Boolean)
}

export const TextWelcomeBodyWallpaper = () => {
  const currentLanguage = CurrentLanguage()
  const title = TextWelcomeTitleWallpaper()
  const { TextWelcomeBodyWallpaper } = useStaticQuery(query)

  const nameMn =
    TextWelcomeBodyWallpaper?.nodes[0]?.data?.nameMn.internal.content ?? ""
  const nameEn =
    TextWelcomeBodyWallpaper?.nodes[0]?.data?.nameEn.internal.content ?? ""
  const name = currentLanguage === "mn" ? nameMn : nameEn

  return (
    <div className="py-8 bg-white md:pt-20 bg-opacity-70">
      <div className="container">
        <div className="flex justify-center mx-auto font-bold">
          <Title title={title} small />
        </div>
        <div className="grid px-8 mx-auto mt-8 prose-sm sm:prose">
          <ReactMarkdown children={name} />
        </div>
      </div>
    </div>
  )
}

export const NewWallpapers = () => {
  const { NewWallpapers } = useStaticQuery(query)

  const products = NewWallpapers.nodes.map((item, index) => {
    const itemId = item?.id ?? ""
    const itemName = item?.data?.Name?.trim() ?? ""
    const itemSharp = getImage(item?.data?.Image?.localFiles[0])
    const itemImage = <GatsbyImage image={itemSharp} alt={itemName} />

    return { itemId, itemName, itemImage }
  })

  return products
}

export const HotWallpapers = () => {
  const { HotWallpapers } = useStaticQuery(query)

  const products = HotWallpapers.nodes.map((item, index) => {
    const itemId = item?.id ?? ""
    const itemName = item?.data?.Name?.trim() ?? ""
    const itemSharp = getImage(item?.data?.Image?.localFiles[0])
    const itemImage = <GatsbyImage image={itemSharp} alt={itemName} />

    return { itemId, itemName, itemImage }
  })

  return products
}

export const BestSellerWallpapers = () => {
  const { BestSellerWallpapers } = useStaticQuery(query)

  const products = BestSellerWallpapers.nodes.map((item, index) => {
    const itemId = item?.id ?? ""
    const itemName = item?.data?.Name?.trim() ?? ""
    const itemSharp = getImage(item?.data?.Image?.localFiles[0])
    const itemImage = <GatsbyImage image={itemSharp} alt={itemName} />

    return { itemId, itemName, itemImage }
  })

  return products
}

export const AllWallpapers = () => {
  const { AllWallpapers } = useStaticQuery(query)

  const products = AllWallpapers.nodes.map((item, index) => {
    const itemId = item?.id ?? ""
    const itemName = item?.data?.Name?.trim() ?? ""
    const itemSharp = getImage(item?.data?.Image?.localFiles[0])
    const itemImage = <GatsbyImage image={itemSharp} alt={itemName} />

    return { itemId, itemName, itemImage }
  })

  return products
}

export const AllWallpaperFullQuery = () => {
  const currentLanguage = CurrentLanguage()
  const { AllWallpaperFullQuery } = useStaticQuery(query)

  const products = AllWallpaperFullQuery.nodes.map((item, index) => {
    // ############ ID, PRODUCTNAME
    const id = item?.id ?? undefined
    const name = item?.data?.Name?.trim() ?? undefined

    // ############ TYPE
    const typeInfo = item?.data?.Type
    const test = typeInfo ? "Type is defined" : "Type undefined"

    const type = typeInfo
      ? typeInfo.map((type, index) => {
          const nameTypeMn = type?.data?.nameTypeMn ?? ""
          const nameTypeEn = type?.data?.nameTypeEn ?? ""
          const name = currentLanguage === "mn" ? nameTypeMn : nameTypeEn

          const sharp = getImage(type?.data?.iconType?.localFiles[0])
          const icon = sharp ? (
            <GatsbyImage image={sharp} alt={`${name} icon`} />
          ) : undefined
          return { name, icon }
        })
      : undefined

    // ############## ONTSLOG
    const ontslogInfo = item?.data?.Ontslog

    const ontslog = ontslogInfo
      ? ontslogInfo.map((subItem, index) => {
          const nameOntslogMn = subItem?.data?.nameOntslogMn ?? ""
          const nameOntslogEn = subItem?.data?.nameOntslogEn ?? ""
          const name = currentLanguage === "mn" ? nameOntslogMn : nameOntslogEn

          const sharp = getImage(subItem?.data?.iconOntslog?.localFiles[0])
          const icon = sharp ? (
            <GatsbyImage image={sharp} alt={`${name} icon`} className="" />
          ) : undefined
          return { name, icon }
        })
      : undefined

    // console.log(ontslog)
    // ################# LUXURY
    const luxuryInfo = item?.data?.Luxury

    const luxury = luxuryInfo
      ? luxuryInfo.map((subItem, index) => {
          const nameLuxuryMn = subItem?.data?.nameLuxuryMn ?? ""
          const nameLuxuryEn = subItem?.data?.nameLuxuryEn ?? ""
          const name = currentLanguage === "mn" ? nameLuxuryMn : nameLuxuryEn

          const sharp = getImage(subItem?.data?.iconLuxury?.localFiles[0])
          const icon = sharp ? (
            <GatsbyImage image={sharp} alt={`${name} icon`} />
          ) : undefined
          return { name, icon }
        })
      : undefined

    // console.log(luxury)

    // ################# Pattern
    const patternInfo = item?.data?.Pattern

    const pattern = patternInfo
      ? patternInfo.map((subItem, index) => {
          const namePatternMn = subItem?.data?.namePatternMn ?? ""
          const namePatternEn = subItem?.data?.namePatternEn ?? ""
          const name = currentLanguage === "mn" ? namePatternMn : namePatternEn

          const sharp = getImage(subItem?.data?.iconPattern?.localFiles[0])
          const icon = sharp ? (
            <GatsbyImage image={sharp} alt={`${name} icon`} />
          ) : undefined
          return { name, icon }
        })
      : undefined

    // console.log(pattern)

    // ################# Room
    const roomInfo = item?.data?.Room

    const room = roomInfo
      ? roomInfo.map((subItem, index) => {
          const nameRoomMn = subItem?.data?.nameRoomMn ?? ""
          const nameRoomEn = subItem?.data?.nameRoomEn ?? ""
          const name = currentLanguage === "mn" ? nameRoomMn : nameRoomEn

          const images = subItem?.data?.Image?.localFiles?.map(
            (item, index) => {
              const sharp = getImage(item)
              const roomImage = sharp ? (
                <GatsbyImage image={sharp} alt={`${name} - ${index + 1}`} />
              ) : undefined
              return roomImage
            }
          )

          const sharp = getImage(subItem?.data?.Icon?.localFiles[0])
          const icon = sharp ? (
            <GatsbyImage image={sharp} alt={`${name} icon`} />
          ) : undefined

          return { name, images, icon }
        })
      : undefined

    // console.log({ name, room })

    // ################## Tailbar

    const TailbarEn = item?.data?.tailbarEn?.internal?.content?.trim() ?? ""
    const TailbarMn = item?.data?.tailbarMn?.internal?.content?.trim() ?? ""
    const tailbar = currentLanguage === "mn" ? TailbarMn : TailbarEn
    const convertedTailbar =
      tailbar && tailbar != "" ? (
        <ReactMarkdown children={tailbar} />
      ) : undefined

    // console.log({ name, convertedTailbar })

    // ################### New
    const isNew = item?.data?.New ?? undefined
    const isHot = item?.data?.Hot ?? undefined
    const isBestseller = item?.data?.Bestseller ?? undefined
    const isModern = item?.data?.Modern ?? undefined
    const isOntslogdson = item?.data?.ontsloh ?? undefined

    // ################### SALE
    const saleInfo = item?.data?.Sale ?? undefined

    const sale = saleInfo ? `${saleInfo * 100}%` : undefined

    // console.log(sale)

    // ##################### COLOR
    const colorInfo = item?.data?.Color

    const color = colorInfo
      ? colorInfo.map((subItem, index) => {
          const nameColorMn = subItem?.data?.nameColorMn?.trim() ?? undefined
          const nameColorEn = subItem?.data?.nameColorEn?.trim() ?? undefined
          const name = currentLanguage === "mn" ? nameColorMn : nameColorEn

          const hex = subItem?.data?.Hex?.trim() ?? undefined

          return { name, hex }
        })
      : undefined

    // console.log({ name, color })

    // #################### COUNTRY
    const countryInfo = item?.data?.Country

    const nameCountryMn = countryInfo
      ? countryInfo[0]?.data?.nameCountryMn
      : undefined
    const nameCountryEn = countryInfo
      ? countryInfo[0]?.data?.nameCountryEn
      : undefined

    const country = currentLanguage === "mn" ? nameCountryMn : nameCountryEn

    // console.log({ name, country })

    // ############## MAIN IMAGE
    const mainImageInfo = item?.data?.imageMain

    const sharp = mainImageInfo
      ? getImage(mainImageInfo.localFiles[0])
      : undefined
    const imageMain = sharp ? (
      <GatsbyImage image={sharp} alt={name} />
    ) : undefined
    // console.log(imageMain)

    // ############### TUSLAH IMAGES
    const tuslahImageInfo = item?.data?.imageTuslah

    const tuslahImages = tuslahImageInfo
      ? tuslahImageInfo.localFiles.map((image, index) => {
          const sharp = getImage(image)
          const tuslahImage = sharp ? (
            <GatsbyImage image={sharp} alt={`${name}-${index}`} />
          ) : undefined
          return tuslahImage
        })
      : undefined

    return {
      id,
      name,
      type,
      ontslog,
      luxury,
      pattern,
      room,
      convertedTailbar,
      isNew,
      isHot,
      isBestseller,
      isModern,
      isOntslogdson,
      sale,
      color,
      country,
      imageMain,
      tuslahImages,
    }
  })

  return products
}

export const MergedWallpapers = () => {
  const hotIds = HotWallpapers().map((item, index) => {
    return item.itemId
  })
  const newIds = NewWallpapers().map(item => {
    return item.itemId
  })
  const bestIds = BestSellerWallpapers().map(item => item.itemId)

  const mergedArray = hotIds.concat(newIds, bestIds)

  const uniqueCollection = new Set(mergedArray) // ene hurtel zuv

  const uniqueArray = [...uniqueCollection] // Array.from(uniqueCollection)

  const uniqueProductInformation = uniqueArray.map((item, index) => {
    const showingProduct = AllWallpapers().filter(
      product => product.itemId === item
    )
    // console.log(showingProduct) // array (with object) result
    const name = showingProduct[0].itemName
    const id = showingProduct[0].itemId
    const image = showingProduct[0].itemImage
    return { name, id, image }
  })

  return uniqueProductInformation

  // const tempProducts = AllWallpapers().filter(product => product.id === )

  // return uniqueArray
}

const query = graphql`
  {
    AllWallpapers: allAirtable(filter: { table: { eq: "Wallpaper" } }) {
      nodes {
        id
        data {
          Name
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
      totalCount
    }
    NiitlegShinj: allAirtable(
      filter: { table: { eq: "Wallpaper Niitleg shinj" } }
    ) {
      nodes {
        data {
          nameEn: en
          nameMn: mn
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 100
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    BestSellerWallpapers: allAirtable(
      filter: { table: { eq: "Wallpaper" }, data: { Bestseller: { eq: true } } }
    ) {
      nodes {
        id
        data {
          Name
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
    HotWallpapers: allAirtable(
      filter: { table: { eq: "Wallpaper" }, data: { Hot: { eq: true } } }
    ) {
      nodes {
        id
        data {
          Name
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
    NewWallpapers: allAirtable(
      filter: { table: { eq: "Wallpaper" }, data: { New: { eq: true } } }
    ) {
      nodes {
        id
        data {
          Name
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
    TextWelcomeTitleWallpaper: allAirtable(
      filter: {
        table: { eq: "Wallpaper page text" }
        data: { Tailbar: { eq: "Ханын цаас хуудасны тайлбар текстийн гарчиг" } }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    TextWelcomeBodyWallpaper: allAirtable(
      filter: { table: { eq: "Wallpaper Description" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_Tailbar {
            internal {
              content
            }
          }
          nameEn: English_Tailbar {
            internal {
              content
            }
          }
        }
      }
    }
    NewWallpaperFullQuery: allAirtable(
      filter: { table: { eq: "Wallpaper" }, data: { New: { eq: true } } }
    ) {
      nodes {
        data {
          Name
          Type {
            data {
              nameTypeMn: mn
              nameTypeEn: en
              iconType: Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          Ontslog {
            data {
              nameOntslogMn: mn
              nameOntslogEn: en
              iconOntslog: Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          Luxury {
            data {
              nameLuxuryEn: en
              nameLuxuryMn: mn
              iconLuxury: Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          Pattern {
            data {
              namePatternEn: en
              namePatternMn: mn
              iconPattern: Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          Room {
            data {
              nameRoomMn: mn
              nameRoomEn: en
            }
          }
          tailbarEn: Nemelt_Tailbar_EN {
            internal {
              content
            }
          }
          tailbarMn: Nemelt_Tailbar_MN {
            internal {
              content
            }
          }
          New
          Hot
          Bestseller
          Sale
          Modern
          imageMain: Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 800)
              }
            }
          }
          Color {
            data {
              nameColorMn: mn
              nameColorEn: en
            }
          }
          Country {
            data {
              nameCountryMn: mn
              nameCountryEn: en
            }
          }
          ontsloh: Ontsloh_baraa_bolgoh
          imageTuslah: Tuslah_zuragnuud {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 800)
              }
            }
          }
        }
      }
    }

    NewWallpapers: allAirtable(
      filter: { table: { eq: "Wallpaper" }, data: { New: { eq: true } } }
    ) {
      nodes {
        id
        data {
          Name
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
    AllWallpaperFullQuery: allAirtable(
      filter: { table: { eq: "Wallpaper" } }
      limit: 10
    ) {
      nodes {
        data {
          Name
          Type {
            data {
              nameTypeMn: mn
              nameTypeEn: en
              iconType: Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 100
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Ontslog {
            data {
              nameOntslogMn: mn
              nameOntslogEn: en
              iconOntslog: Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 100
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Luxury {
            data {
              nameLuxuryEn: en
              nameLuxuryMn: mn
              iconLuxury: Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 100
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Pattern {
            data {
              namePatternEn: en
              namePatternMn: mn
              iconPattern: Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 100
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Room {
            data {
              nameRoomMn: mn
              nameRoomEn: en
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          tailbarEn: Nemelt_Tailbar_EN {
            internal {
              content
            }
          }
          tailbarMn: Nemelt_Tailbar_MN {
            internal {
              content
            }
          }
          New
          Hot
          Bestseller
          Sale
          Modern
          imageMain: Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 800)
              }
            }
          }
          Color {
            data {
              nameColorMn: mn
              nameColorEn: en
              Hex: Hex_Color
            }
          }
          Country {
            data {
              nameCountryMn: mn
              nameCountryEn: en
            }
          }
          ontsloh: Ontsloh_baraa_bolgoh
          imageTuslah: Tuslah_zuragnuud {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 800)
              }
            }
          }
        }
        id
      }
    }
  }
`
